<script setup lang="ts">
const local_site = useLocalSite();
const route = useRoute();
</script>

<template>
  <component
    class="flex items-center gap-2 px-1 w-2/3 max-w-[240px]"
    :is="route.path == '/' ? 'h1' : 'div'"
  >
    <NuxtLink to="/">
      <ImageEl
        src="/images/logo_1.png"
        alt="外構・庭園・エクステリア・水まわり工事のファミリー庭園"
        width="766"
        height="195"
        sizes="160px"
        format="webp"
      />
    </NuxtLink>
    <NuxtLink
      class="border-y-2 border-black px-3 text-sm py-0.5 whitespace-nowrap"
      :to="`/contents.php/koujiarea#${local_site.shop_key}`"
    >
      {{ local_site.shop_name }}
    </NuxtLink>
  </component>
</template>
