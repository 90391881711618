<script setup lang="ts">
const local_site = useLocalSite();
</script>

<template>
  <ButtonTelLink
    class="flex items-center justify-center px-2 py-4"
    :tel="local_site.tel"
  >
    <Icon class="text-white mr-2" size="24" name="mdi:cellphone" />
    <p>電話でお問い合わせ</p>
  </ButtonTelLink>
</template>
