<script setup lang="ts">
import useHistory from "~/composables/useHistory";
import { useIntersectionObserver } from "@vueuse/core";

const { historyItems, fetch } = useHistory();

const historyEl = ref<HTMLElement | null>(null);

const { stop } = useIntersectionObserver(historyEl, ([{ isIntersecting }]) => {
  // 要素が画面内に入ったときに履歴がなければ初回ロード
  if (isIntersecting && !historyItems.value.length) {
    fetch();
    // 監視を止める
    stop();
  }
});
</script>

<template>
  <LayoutWrapperDiv
    v-if="historyItems.length"
    ref="historyEl"
    class="relative mb-8"
  >
    <p class="absolute -top-3 left-6 text-lg z-10 bg-white">最近見たページ</p>
    <ul
      class="flex gap-4 overflow-x-auto border border-dotted p-4 relative border-gray-500 rounded"
    >
      <li v-for="(d, idx) in historyItems" :key="idx" class="">
        <NuxtLink
          class="block border rounded w-32 tablet:w-48 h-full hover:opacity-80"
          :to="d.link"
        >
          <ImageEl
            provider="customimgproxy"
            class="w-full"
            :src="d.img"
            :alt="`${d.sekou_cd ? '施工例' : '外構プラン事例'} ${d.name}`"
            width="500"
            height="375"
            sizes="128px md:192px"
            format="webp"
            loading="lazy"
          />
          <p class="p-2">
            <span class="text-sm tablet:text-base line-clamp-3">{{
              d.name
            }}</span>
          </p>
        </NuxtLink>
      </li>
    </ul>
  </LayoutWrapperDiv>
</template>
