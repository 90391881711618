<script setup lang="ts">
import type { FavoriteItem } from "~/types";
import { VueFinalModal } from "vue-final-modal";

const attrs = useAttrs() as FavoriteItem;

const labels = ref<string[]>([...(attrs.label ?? [])]);

const inputText = ref("");

// 全ラベル
const allLabels = useFavoriteLabels();
const datalistId = useId();

const emits = defineEmits<{
  (e: "submit", value: { label: string[]; cd: string; num?: number }): void;
  (e: "close"): void;
}>();

// ラベルを追加
function addLabel() {
  if (!inputText.value.trim() || labels.value?.includes(inputText.value))
    return;
  labels.value.push(inputText.value);
  inputText.value = "";
}

// ラベルを削除
function deleteLabel(label: string) {
  labels.value = labels.value?.filter((e) => e != label);
}

function onSubmit() {
  // 追加をしていないラベルはinputに残っている
  if (inputText.value) {
    alert(
      "入力したラベルは追加されていません。追加する場合「追加」を押してください",
    );
    return;
  }

  emits("submit", { label: labels.value, cd: attrs.cd, num: attrs.num });
}

function onClose() {
  // 変更があるとき破棄していいか確認
  if (
    JSON.stringify(labels.value) !== JSON.stringify(attrs.label) &&
    !confirm("変更を破棄してもよろしいですか？")
  )
    return;

  emits("close");
}
</script>

<template>
  <VueFinalModal
    class="flex items-center"
    content-class="bg-white rounded p-2 relative max-w-xl mx-auto"
    content-transition="vfm-fade"
  >
    <p class="font-bold text-lg mb-4">ラベル管理</p>
    <p class="">ラベル一覧</p>
    <ul class="flex flex-wrap gap-2 items-center mb-4">
      <li v-if="!labels || !labels.length" class="text-gray-400">
        ラベルはありません
      </li>
      <li
        v-for="label in labels"
        :key="label"
        class="flex items-center border rounded-lg p-1 leading-none"
        @click="deleteLabel(label)"
      >
        <span class="mr-1"> {{ label }}</span>
        <button
          class="cursor-pointer text-red-500 rounded-full p-1 hover:bg-red-500 hover:text-white"
        >
          <Icon size="16" name="mdi:delete" />
        </button>
      </li>
    </ul>
    <div class="flex items-end mb-8">
      <label class="w-2/3 mr-2">
        新しいラベルを追加
        <input
          v-model="inputText"
          class="border w-full py-2"
          type="text"
          :list="datalistId"
        />
        <datalist :id="datalistId">
          <option v-for="label in allLabels" :key="label" :value="label" />
        </datalist>
      </label>
      <button
        class="bg-gray-500 border text-white rounded py-2 w-1/3"
        @click="addLabel"
      >
        追加
      </button>
    </div>

    <div class="flex justify-end">
      <button class="py-2 w-1/3" @click="onClose">閉じる</button>
      <button
        class="bg-blue-500 border text-white rounded py-2 w-1/3"
        @click="onSubmit"
      >
        完了
      </button>
    </div>
  </VueFinalModal>
</template>
