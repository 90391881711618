<script setup lang="ts">
const { showScrollTop } = useScrollTop(false);
showScrollTop.value = false;
function scrollTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

const isShow = ref(false);

function showButton() {
  if (100 <= window.scrollY) {
    isShow.value = true;
  } else {
    isShow.value = false;
  }
}

onMounted(() => {
  window.addEventListener("scroll", showButton, { passive: true });
});
</script>

<template>
  <Transition name="slide-fade-r">
    <ButtonFloatingRound
      v-show="showScrollTop && isShow"
      class="bottom-4 right-4"
      color="secondary"
      @click="scrollTop()"
    >
      <Icon size="32" name="mdi:chevron-up" />
    </ButtonFloatingRound>
  </Transition>
</template>
