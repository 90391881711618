<script setup lang="ts">
const local_site = useLocalSite();
</script>
<template>
  <p class="flex flex-col text-center">
    <span class="text-xl leading-3 font-bold my-1">
      <Icon class="mr-2" name="mdi:phone" />{{ local_site.tel }}</span
    >
    <span class="px-1 border text-xs bg-gray-500 text-white rounded"
      >10:00~18:00 水・木 定休日</span
    >
  </p>
</template>
