<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
// 型が解決できないので、明示的にimportする
import { addComma } from "#imports";

const { baseMenu } = await useMenu();
const props = defineProps<{
  onClose: () => void;
}>();
</script>

<template>
  <VueFinalModal
    class="flex"
    contentClass="bg-white p-4 relative w-full overflow-y-scroll select-none"
    content-transition="vfm-slide-up"
    swipe-to-close="up"
    :threshold="200"
  >
    <button
      class="text-green-800 absolute right-2 top-2"
      @click="props.onClose()"
    >
      <Icon size="32" name="mdi:close" s />
    </button>

    <NuxtLink to="/">
      <ImageEl
        class="w-[180px] mx-auto mb-4"
        src="/images/logo_1.png"
        alt="外構・庭園・エクステリア・水まわり工事のファミリー庭園"
        width="766"
        height="195"
        sizes="sm:270px"
        format="webp"
        @click="props.onClose()"
      />
    </NuxtLink>
    <div class="px-2">
      <p class="flex justify-center text-center text-sm mb-1">
        営業時間10:00~18:00 水・木 定休日
      </p>
    </div>
    <div class="mb-2" :class="$style['btn-green']">
      <NuxtLink
        class="flex items-center justify-center px-2 py-4"
        to="/inquiry/form"
        @click="props.onClose()"
      >
        <Icon class="text-white mr-2" size="24" name="mdi:email" />
        <p>お問い合せフォーム</p>
      </NuxtLink>
    </div>
    <div class="mb-2" :class="$style['btn-green']">
      <HeaderTelButton />
    </div>

    <ul class="grid grid-cols-2 gap-1 bg-gray-100 p-1">
      <li
        v-for="d in baseMenu"
        :key="d.name"
        class="bg-white hover:bg-gray-100 text-famitei-green whitespace-nowrap"
        :class="{ 'col-span-2': d.label }"
      >
        <NuxtLink
          :to="d.to"
          class="flex items-center p-2 relative"
          @click="props.onClose()"
        >
          <p>{{ d.name }}</p>
          <p
            v-if="d.label"
            class="absolute right-1 text-red-500 bg-red-100 rounded-lg px-1"
          >
            <span v-if="d.label?.count" class="font-bold mr-1"
              >{{ addComma(d.label.count) }}
            </span>
            <span v-if="d.label?.unit" class="text-xs">
              {{ d.label.unit }}</span
            >
          </p>
        </NuxtLink>
      </li>
    </ul>
  </VueFinalModal>
</template>

<style module>
.btn-green {
  @apply bg-famitei-green text-center text-white whitespace-nowrap;
}
</style>
