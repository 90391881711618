<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import useSekouFavorite from "~/composables/useSekouFavorite";
import usePlanFavorite from "~/composables/usePlanFavorite";
// この型が解決できないみたいなので、importを書いておく
import { isActiveStorage } from "#imports";

defineProps<{
  onClose: () => void;
}>();

const { favoriteSekou, deleteSekou, editSekou } = useSekouFavorite();
const { favoritePlan, deletePlan, editPlan } = usePlanFavorite();

// お気に入りについているすべてのラベル
const labelList = computed(() => {
  const labels = new Set([
    ...favoriteSekou.value.flatMap((e) => {
      if (e.label) return e.label;
      return [];
    }),
    ...favoritePlan.value.flatMap((e) => {
      if (e.label) return e.label;
      return [];
    }),
  ]);
  return labels;
});

// ラベル一覧の中身がなくなったとき全表示にする
watch(
  () => labelList.value,
  () => {
    if (!labelList.value.size) {
      selectLabel.value = "";
    }
  },
);

// 絞り込みするラベル
const selectLabel = ref("");

// フィルタ済み施工例リスト
const filteredSekouList = computed(() => {
  let list = favoriteSekou.value.map((e) => e);

  if (selectLabel.value) {
    list = list.filter((e) => e.label?.includes(selectLabel.value));
  }
  return list;
});

// フィルタ済みリスト
const filteredPlanList = computed(() => {
  let list = favoritePlan.value.map((e) => e);

  if (selectLabel.value) {
    list = list.filter((e) => e.label?.includes(selectLabel.value));
  }
  return list;
});
</script>

<template>
  <VueFinalModal
    content-class="bg-white relative h-full overflow-scroll max-w-4xl mx-auto"
    content-transition="vfm-slide-down"
    swipe-to-close="down"
    :threshold="100"
  >
    <div class="p-4 pc:p-8">
      <button
        type="button"
        class="text-green-800 absolute right-2 top-2"
        aria-label="close"
        @click="onClose()"
      >
        <Icon size="32" name="mdi:close" />
      </button>
      <p class="text-xl mb-4">お気に入り</p>
      <div v-if="filteredSekouList.length || filteredPlanList.length">
        <label class="flex items-center mb-4">
          <span class="mr-2">絞り込み</span>
          <select
            v-model="selectLabel"
            class="flex-grow border rounded p-2 bg-white"
          >
            <option value="">すべて</option>
            <option v-for="label in labelList" :key="label">
              {{ label }}
            </option>
          </select>
        </label>
      </div>
      <Transition name="fade">
        <div
          v-if="!filteredSekouList.length && !filteredPlanList.length"
          class="absolute left-0 top-1/2 -translate-y-[150px] w-full text-center px-4"
        >
          <p class="mb-8">お気に入りリストが空のようです。</p>
          <p v-if="isActiveStorage('localStorage')">
            施工例、プラン事例の一覧または詳細からお気に入りに登録することができます
          </p>
          <p v-else class="text-red-500 font-bold">
            CookieがオフになっているかWebStorage機能が制限されているため、お気に入り機能は利用できません
          </p>
          <NuxtLink
            to="/sekou"
            class="block text-white bg-famitei-green hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-3 my-4 cursor-pointer"
            @click="onClose()"
          >
            施工例を見る
          </NuxtLink>
          <NuxtLink
            to="/plan"
            class="block text-white bg-famitei-green hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-3 my-4 cursor-pointer"
            @click="onClose()"
          >
            プラン事例を見る
          </NuxtLink>
        </div>
      </Transition>

      <FavoriteList
        v-if="filteredSekouList.length"
        title="施工例"
        type="sekou"
        :list="filteredSekouList"
        @close="onClose()"
        @delete="({ cd, num }) => deleteSekou(cd, num)"
        @edit="({ label, cd, num }) => editSekou(label, cd, num)"
      />

      <FavoriteList
        v-if="filteredPlanList.length"
        title="プラン事例"
        type="plan"
        :list="filteredPlanList"
        @close="onClose()"
        @delete="({ cd }) => deletePlan(cd)"
        @edit="({ label, cd }) => editPlan(label, cd)"
      />
    </div>
  </VueFinalModal>
</template>
